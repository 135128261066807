import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Breadcrumb from "../../components/breadcrumb/index";
import DataTable_Component from "../../components/vehicles/Data Table Component";
import Add_data_modal from "../../components/vehicles/update_data_modal/index";
import VehicleDetailModal from "../../components/vehicles/DisplayData";
import Swal from "sweetalert2";
import { Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Layouts from "../../layouts/layouts";
import VehicleHistory from "./vehiclehistory";
import * as XLSX from "xlsx";
import {
  faPenToSquare,
  faTrash,
  faCopy,
  faEye,
  faHistory,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "react-tooltip";

export default function Vehicle() {
  const navigate = useNavigate();
  const title = "Vehicles Details";
  const [apistate, setApiState] = useState([]);
  const [apicol, setApiCol] = useState([]);
  const [selectVal, setSelectval] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [vehicleCount, setVehicleCount] = useState(0);
  const [movingVehiclesCount, setMovingVehiclesCount] = useState(0);
  const [notMovingVehiclesCount, setNotMovingVehiclesCount] = useState(0);
  const [totalVehicles, setTotalVehicles] = useState(0);

  const [filter, setFilter] = useState("all");

  const [open_add_modal, setOpen_add_modal] = useState({
    render: true,
    modal_open: false,
  });
  const [open_update_modal, setOpen_update_modal] = useState({
    render: true,
    modal_open: false,
  });
  const [update_modal_data, setUpdate_modal_data] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [showVehicleDetailModal, setShowVehicleDetailModal] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [data, setData] = useState([]);
  const brad = [{ name: "Home" }, { name: title }];

  const isToday = (dateString) => {
    if (!dateString) return false;

    const today = new Date();
    const dateToCheck = new Date(dateString);

    return (
      today.getDate() === dateToCheck.getDate() &&
      today.getMonth() === dateToCheck.getMonth() &&
      today.getFullYear() === dateToCheck.getFullYear()
    );
  };

  const formatTime = (dateString) => {
    console.log("Original Date String:", dateString); // Check the original date string
    if (!dateString) {
      console.log("No date provided, returning 'N/A'");
      return "N/A";
    }
    const formattedTime = moment(dateString).format("HH:mm:ss");
    console.log("Formatted Time:", formattedTime); // Check the formatted time
    return formattedTime;
  };

  const columns = [
    {
      name: <b>Number Plate</b>,
      selector: (row) => row.numberplate,
      sortable: true,
      cell: (row) => (
        <div style={{ whiteSpace: "nowrap", overflow: "visible" }}>
          {row.numberplate}
        </div>
      ),
    },
    {
      name: <b>Vehicle Status</b>,
      selector: (row) => row.vehiclestatus || "N/A",
      sortable: true,
    },
    {
      name: <b>GPS Status</b>,
      selector: (row) => (row.gpsstatus ? "True" : "False"),
      sortable: true,
    },
    {
      name: <b>Driver Name</b>,
      cell: (row) => (
        <div>
          {row.id_driver && row.id_driver.length > 0 ? (
            <div>
              {row.id_driver.map((driver) =>
                driver.id_user && driver.id_user.name ? (
                  <Badge key={driver._id} pill bg="white" text="black">
                    {driver.id_user?.name}
                  </Badge>
                ) : (
                  "  N/A"
                )
              )}
            </div>
          ) : (
            <span>N/A</span>
          )}
        </div>
      ),
      sortable: true,
    },
    {
      name: <b>Current Driver </b>,
      selector: (row) =>
        (row.current_driver && row.current_driver?.id_user?.name) || "-",
      sortable: true,
      cell: (row) => (
        <div style={{ whiteSpace: "nowrap", overflow: "visible" }}>
          {(row.current_driver && row.current_driver?.id_user?.name) || "-"}
        </div>
      ),
    },
    {
      name: <b>Last Driver </b>,
      selector: (row) =>
        (row.last_driver && row.last_driver?.id_user?.name) || "-",
      sortable: true,
      cell: (row) => (
        <div style={{ whiteSpace: "nowrap", overflow: "visible" }}>
          {(row.last_driver && row.last_driver?.id_user?.name) || "-"}
        </div>
      ),
    },
    {
      name: <b>Live Location</b>,
      cell: (row) => (
        <div style={{ position: "relative", zIndex: 1 }}>
          {row.location ? (
            <>
              <span>{row.location.slice(0, 20)}...</span>
              <FontAwesomeIcon
                icon={faInfoCircle}
                data-tooltip-id={`tooltip-live-location-${row._id}`}
                data-tooltip-content={row.location}
                style={{ cursor: "pointer", marginLeft: "5px" }}
              />
              <Tooltip
                id={`tooltip-live-location-${row._id}`}
                place="bottom"
                style={{ zIndex: 9999, backgroundColor: "black" }} // Ensures the tooltip appears on top
              />
            </>
          ) : (
            "N/A"
          )}
        </div>
      ),
      sortable: true,
    },

    {
      name: <b>Actual Location</b>,
      cell: (row) => (
        <div style={{ position: "relative", zIndex: 1 }}>
          {row.assignedlocation ? (
            <>
              <span>{row.assignedlocation.slice(0, 20)}...</span>
              <FontAwesomeIcon
                icon={faInfoCircle}
                data-tooltip-id={`tooltip-actual-location-${row._id}`}
                data-tooltip-content={row.assignedlocation}
                style={{ cursor: "pointer", marginLeft: "5px" }}
              />
              <Tooltip
                id={`tooltip-actual-location-${row._id}`}
                place="bottom"
                style={{ zIndex: 9999, backgroundColor: "black" }} // Ensures the tooltip appears on top of other elements
              />
            </>
          ) : (
            "N/A"
          )}
        </div>
      ),
      sortable: true,
    },

    {
      name: <b>Moving Day Status</b>,
      selector: (row) => (row.movingdaysstatus ? row.movingdaysstatus : "0"),
      sortable: true,
    },
    {
      name: <b>Total KM</b>,
      selector: (row) => Math.round(row.totalkm),
      sortable: true,
    },
    {
      name: <b>Daily KM</b>,
      selector: (row) => Math.round(row.dailyKm) || "0",
    },

    {
      name: <b>Vehicle Company</b>,
      selector: (row) => (row.id_type ? row.id_type.company : "N/A"),
      sortable: true,
    },

    {
      name: <b>Check In</b>,
      // selector: (row) => formatTime(row.shift_hours?.check_in),
      selector: (row) =>
        isToday(row.shift_hours?.check_in)
          ? formatTime(row.shift_hours?.check_in)
          : "-",
      sortable: true,
    },
    {
      name: <b>Check Out</b>,
      // selector: (row) => formatTime(row.shift_hours?.check_out),
      selector: (row) =>
        isToday(row.shift_hours?.check_out)
          ? formatTime(row.shift_hours?.check_out)
          : "-",
      sortable: true,
    },
    {
      name: <b>Hubs</b>,
      selector: (row) => {
        if (Array.isArray(row.id_hub)) {
          return row.id_hub.map((hub) => hub.name).join(", ");
        } else {
          return "N/A";
        }
      },
      sortable: true,
    },

    {
      name: <b>Vendor</b>,
      selector: (row) => (row.id_vendor ? row.id_vendor?.id_user?.name : "-"),
      // selector: (row) => row.id_vendor?.vendorname,
      sortable: true,
      cell: (row) => (
        <div style={{ whiteSpace: "nowrap", overflow: "visible" }}>
          {row.id_vendor?.id_user?.name}
        </div>
      ),
    },

    {
      name: <b>Updated At</b>,
      selector: (row) => row.updatedAt && formatTime(row.updatedAt),
      sortable: true,
    },
    {
      name: <b>Status</b>,
      cell: (row) => {
        const status = row.status?.toLowerCase();
        return (
          <button
            onClick={() => handleShow(row)}
            className={`btn px-2 py-1 ${
              // Adjusted padding for space
              status === "active"
                ? "btn-success"
                : status === "inactive"
                ? "btn-danger"
                : status === "unassigned"
                ? "btn-danger"
                : status === "service_center"
                ? "btn-warning"
                : ""
            }`}
            style={{
              minWidth: "70px", // Increased minimum width to fit longer text
              fontSize: "14px", // Adjust font size if needed
              whiteSpace: "nowrap", // Prevents text from wrapping to new lines
            }}
          >
            {row.status || "Unknown"}
          </button>
        );
      },
      // Adding width for the column itself to avoid overlap
      width: "150px", // Adjust width as needed
    },
    {
      name: <b>Action</b>,
      cell: (row) => (
        <>
          <button
            onClick={() => setUpdateStoreBtn(row)}
            className="btn btn-info btn-sm ms-1"
          >
            <FontAwesomeIcon icon={faPenToSquare} />
          </button>
          <button
            onClick={() => delete_row(row)}
            className="btn btn-danger btn-sm ms-1"
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
          <button
            onClick={() => viewVehicle(row)}
            className="btn btn-primary btn-sm ms-1"
          >
            <FontAwesomeIcon icon={faEye} />
          </button>
          <button
            onClick={() => handleVehicleHistoryClick(row)}
            className="btn btn-warning btn-sm ms-1"
          >
            <FontAwesomeIcon icon={faHistory} />
          </button>
        </>
      ),
      // Adding width to Action column to prevent button overflow
      width: "180px", // Adjust width for action buttons
    },
  ];

  const handleShow = (row) => {
    setOpenModal(!openModal);
    setSelectval(row);
  };

  const fetchData = async (page) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found");
        return;
      }

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/vehicles`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (Array.isArray(response.data.vehicles)) {
        const today = new Date().toLocaleDateString();
        const allVehicles = response.data.vehicles;

        // Apply filtering based on the selected filter (moving, not-moving, or all)
        let filteredVehicles = allVehicles;

        if (filter === "moving") {
          filteredVehicles = allVehicles.filter(
            (vehicle) =>
              vehicle.dailyKm !== 0 ||
              vehicle.vehiclestatus === "Moving" ||
              (vehicle.current_driver?.id_user?.attendance?.check_in &&
                new Date(
                  vehicle.current_driver.id_user.attendance.check_in
                ).toLocaleDateString() === today)
          );
        } else if (filter === "not-moving") {
          filteredVehicles = allVehicles.filter(
            (vehicle) =>
              !(
                vehicle.dailyKm !== 0 ||
                vehicle.vehiclestatus === "Moving" ||
                (vehicle.current_driver?.id_user?.attendance?.check_in &&
                  new Date(
                    vehicle.current_driver.id_user.attendance.check_in
                  ).toLocaleDateString() === today)
              )
          );
        }

        // Set the state with filtered data
        setApiState(filteredVehicles);
        setVehicleCount(filteredVehicles.length);

        // Calculate the count for moving and non-moving vehicles
        const movingVehicles = allVehicles.filter(
          (vehicle) =>
            vehicle.dailyKm !== 0 ||
            vehicle.vehiclestatus === "Moving" ||
            (vehicle.current_driver?.id_user?.attendance?.check_in &&
              new Date(
                vehicle.current_driver.id_user.attendance.check_in
              ).toLocaleDateString() === today)
        );
        setMovingVehiclesCount(movingVehicles.length);
        setNotMovingVehiclesCount(allVehicles.length - movingVehicles.length);
        setTotalVehicles(allVehicles.length);
      } else {
        console.error("Unexpected data format from API", response.data);
        setApiState([]);
      }
      setApiCol(columns);
    } catch (error) {
      console.error("Error fetching data: ", error);
      setApiState([]);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage, filter]);

  const handleFilterClick = (type) => {
    setFilter(type);
  };

  const setUpdateStoreBtn = (row) => {
    setOpen_update_modal({
      render: !open_update_modal.render,
      modal_open: true,
    });
    setUpdate_modal_data(row);
  };

  const delete_row = (row) => {
    const token = localStorage.getItem("token");
    if (!token) {
      Swal.fire("Unauthorized!");
      return;
    }
    Swal.fire({
      icon: "warning",
      title: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(
            `${process.env.REACT_APP_BASE_URL}/api/v1/vehicle/${row._id} `,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then(() => {
            Swal.fire("Deleted!", "Vehicle has been deleted.", "success");
            fetchData();
          })
          .catch((error) => {
            Swal.fire("Error!", "Vehicle could not be deleted.", "error");
          });
      }
    });
  };

  const updateVehicle = (updatedVehicle) => {
    setApiState((prev) =>
      prev.map((vehicle) =>
        vehicle._id === updatedVehicle._id ? updatedVehicle : vehicle
      )
    );
  };

  const viewVehicle = (vehicle) => {
    setSelectedVehicle(vehicle);
    setShowVehicleDetailModal(true);
  };

  const handleVehicleHistoryClick = (row) => {
    navigate(`/vehiclehistory/${row._id}`);
  };

  const exportToExcel = () => {
    const transformedData = apistate.map((vehicle) => {
      return {
        "Number Plate": vehicle.numberplate,
        "Vehicle Status": vehicle.vehiclestatus || "N/A",
        "Vehicle Type": vehicle.vehicletype || "N/A",
        Model: vehicle.model || "N/A",
        Year: vehicle.year || "N/A",
        Color: vehicle.color || "N/A",
        Capacity: vehicle.capacity || "N/A",
        Lease: vehicle.lease || "N/A",
        Length: vehicle.length || "N/A",
        "Created By": vehicle.createdby || "N/A",
        "Created Date": vehicle.createddate
          ? new Date(vehicle.createddate).toLocaleDateString()
          : "N/A",
        Comments: vehicle.comments || "N/A",
        "Vehicle Company": vehicle.id_type ? vehicle.id_type.company : "N/A",
        "Driver Name": vehicle.id_driver
          ? vehicle.id_driver.map((driver) => driver.drivername).join(", ")
          : "N/A",
        "Hub Name": vehicle.id_hub ? vehicle.id_hub.name : "N/A",
        "Total KM": vehicle.totalkm,
        Latitude: vehicle.livecoordinates
          ? vehicle.livecoordinates.latitude
          : "N/A",
        Longitude: vehicle.livecoordinates
          ? vehicle.livecoordinates.longitude
          : "N/A",
        "Modified At": vehicle.updatedAt
          ? formatTime(vehicle.updatedAt)
          : "N/A",
        Status: vehicle.status,
      };
    });

    const worksheet = XLSX.utils.json_to_sheet(transformedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Vehicles");
    XLSX.writeFile(workbook, "VehiclesData.xlsx");
  };

  const getVehicleCompanyCounts = (vehicles) => {
    return vehicles.reduce((acc, vehicle) => {
      const company = vehicle.id_type ? vehicle.id_type.company : "N/A";
      acc[company] = (acc[company] || 0) + 1;
      return acc;
    }, {});
  };

  const vehicleCompanyCounts = getVehicleCompanyCounts(apistate);

  return (
    <>
      <Layouts />
      <Add_data_modal
        select_data={update_modal_data}
        open_update_data_modal={open_update_modal}
        onUpdateVehicle={updateVehicle}
      />
      <VehicleDetailModal
        show={showVehicleDetailModal}
        onHide={() => setShowVehicleDetailModal(false)}
        vehicle={selectedVehicle}
      />
      <div className="container-fluid">
        <div className="row my-3">
          <div className="col-12">
            <div className="card" style={{ borderTop: "2px solid #4723d9" }}>
              <div className="card-header d-flex justify-content-between align-items-center border-bottom pb-1">
                <h4>{title}</h4>
                <div className="d-flex justify-content-between align-items-center mb-3">
                  {/* <h5>Total Vehicles: {vehicleCount}</h5> */}
                  <div className="d-flex align-items-center">
                    <button
                      className="me-4"
                      style={{
                        backgroundColor: "green",
                        color: "white",
                        borderColor: "white",
                      }}
                      onClick={() => handleFilterClick("moving")}
                    >
                      Moving: {movingVehiclesCount}{" "}
                    </button>
                    <button
                      className="me-4"
                      style={{
                        backgroundColor: "red",
                        color: "white",
                        borderColor: "white",
                      }}
                      onClick={() => handleFilterClick("not-moving")}
                    >
                      Not Moving: {notMovingVehiclesCount}{" "}
                    </button>
                    <button
                      className="me-4"
                      style={{
                        backgroundColor: "blue",
                        color: "white",
                        borderColor: "white",
                      }}
                      onClick={() => handleFilterClick("total")}
                    >
                      Total: {totalVehicles}{" "}
                    </button>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <Link to="/create-vehicle">
                    <div className="btn btn-info btn-sm text-white me-2">
                      Add Vehicle
                    </div>
                  </Link>
                  <button
                    onClick={exportToExcel}
                    className="btn btn-success btn-sm me-2"
                  >
                    Export to Excel
                  </button>
                </div>
              </div>
              <div className="card-body">
                {apistate && apistate.length > 0 ? (
                  <DataTable_Component
                    searchBy="numberplate"
                    title_btn={true}
                    title="Vehicle List"
                    apidata={apistate}
                    columns={apicol}
                  />
                ) : (
                  <p>Loading...</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
