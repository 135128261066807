import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCopy,
  faDatabase,
  faCreditCard,
  faEyeLowVision,
  faBuildingColumns,
  faFileInvoice,
} from "@fortawesome/free-solid-svg-icons";
import CountUp from "react-countup";
import Swal from "sweetalert2";
import axios from "axios";
import { Pie } from "react-chartjs-2";
import Chart from "chart.js/auto";
import { AreaChart, DonutChart } from "./chart/chart";
import { Bar } from "react-chartjs-2"; // Import Bar chart
import MyMapComponent from "./MyMapComponent";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

export default function Hero() {
  const [data, setData] = useState([]);
  const [driverCount, setDriverCount] = useState(0);
  const [vehicleCount, setVehicleCount] = useState(0);
  const [hubCount, setHubCount] = useState(0);
  const [clientCount, setClientCount] = useState(0);
  const [userCount, setUserCount] = useState(0);
  const [generalStaffCount, setGeneralStaffCount] = useState(0);
  const [totalKm, setTotalKm] = useState(0);
  const [dailyKm, setDailykm] = useState(0);
  const [rerendarApi, setRerendarApi] = useState(false);
  const [vehicleData, setVehicleData] = useState([]);
  const tokennew = "your-token"; // Replace with your actual token

  const [statusChartData, setStatusChartData] = useState({
    labels: [],
    // datasets: [
    //   {
    //     data: [],
    //     backgroundColor: [],
    //   },
    // ],
    datasets: [
      {
        label: "Active",
        backgroundColor: "#4CAF50",
        data: [],
      },
      {
        label: "Inactive",
        backgroundColor: "#F44336",
        data: [],
      },
      {
        label: "Unassigned",
        backgroundColor: "#FFC107",
        data: [],
      },
      {
        label: "Service Centre",
        backgroundColor: "#2196F3",
        data: [],
      },
    ],
  });

  const [barChartData, setBarChartData] = useState({
    labels: [],
    datasets: [],
  });

  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [],
      },
    ],
  });
  const token = localStorage.getItem("token");

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/v1/vehicles`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const vehicles = response.data.vehicles;
        console.log("Fetched Vehicles:", vehicles); // Log the raw vehicle data

        setVehicleCount(vehicles.length);

        // Group vehicles by company and status
        const groupedData = vehicles.reduce((acc, vehicle) => {
          const company = vehicle.id_type?.company || "Unknown";
          const status = vehicle.status || "Unknown";

          console.log("Company:", company, "Status:", status); // Log each company's status

          if (!acc[company]) {
            acc[company] = {
              active: 0,
              inactive: 0,
              unassigned: 0,
              servicecentre: 0,
            };
          }

          // Increment the count based on the vehicle status
          if (status === "Active") acc[company].active++;
          if (status === "Inactive") acc[company].inactive++;
          if (status === "Unassigned") acc[company].unassigned++;
          if (status === "Service_Center") acc[company].servicecentre++;

          return acc;
        }, {});

        // Prepare data for the multi-bar chart
        const companyLabels = Object.keys(groupedData);
        const activeData = companyLabels.map(
          (company) => groupedData[company].active || 0
        );
        const inactiveData = companyLabels.map(
          (company) => groupedData[company].inactive || 0
        );
        const unassignedData = companyLabels.map(
          (company) => groupedData[company].unassigned || 0
        );
        const servicecentreData = companyLabels.map(
          (company) => groupedData[company].servicecentre || 0
        );

        console.log("Active Data:", activeData); // Log processed active data
        console.log("Inactive Data:", inactiveData); // Log processed inactive data
        console.log("Unassigned Data:", unassignedData); // Log processed inactive data
        console.log("Service Centre Data:", servicecentreData); // Log processed inactive data

        setBarChartData({
          labels: companyLabels,
          datasets: [
            {
              label: "Active",
              backgroundColor: "#4CAF50",
              data: activeData,
            },
            {
              label: "Inactive",
              backgroundColor: "#F44336",
              data: inactiveData,
            },
            {
              label: "Unassigned",
              backgroundColor: "#FFC107",
              data: unassignedData,
            },
            {
              label: "Service Centre",
              backgroundColor: "#2196F3",
              data: servicecentreData,
            },
          ],
        });
      })
      .catch((error) => {
        console.error("Error fetching vehicle data:", error);
      });
  }, [token]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/v1/vehicles`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const vehicles = response.data.vehicles;
        setVehicleCount(vehicles.length);

        // Calculate vehicle company distribution
        const groupedCompanyData = vehicles.reduce((acc, vehicle) => {
          const company = vehicle.id_type?.company || "Unknown";
          if (!acc[company]) {
            acc[company] = 0;
          }
          acc[company]++;
          return acc;
        }, {});

        const companyLabels = Object.keys(groupedCompanyData);
        const companyData = Object.values(groupedCompanyData);
        const companyColors = companyLabels.map(
          () => `#${Math.floor(Math.random() * 16777215).toString(16)}`
        );

        setChartData({
          labels: companyLabels,
          datasets: [
            {
              data: companyData,
              backgroundColor: companyColors,
            },
          ],
        });

        // Calculate vehicle status distribution
        const groupedStatusData = vehicles.reduce((acc, vehicle) => {
          const status = vehicle.status || "Unknown"; // Change 'status' to the correct field name
          if (!acc[status]) {
            acc[status] = 0;
          }
          acc[status]++;
          return acc;
        }, {});

        const statusLabels = Object.keys(groupedStatusData);
        const statusData = Object.values(groupedStatusData);
        const statusColors = statusLabels.map(
          () => `#${Math.floor(Math.random() * 16777215).toString(16)}`
        );

        setStatusChartData({
          labels: statusLabels,
          datasets: [
            {
              data: statusData,
              backgroundColor: statusColors,
            },
          ],
        });
      })
      .catch((error) => {
        console.error("Error fetching vehicle data:", error);
      });
  }, [tokennew]);

  const options = {
    plugins: {
      legend: {
        display: false, // Disable the legend on the chart itself
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            // Customize tooltip display if needed
            return context.label + ": " + context.formattedValue;
          },
        },
      },
    },
  };

  //DRIVER
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/v1/drivers`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setDriverCount(response.data.driverCount); // Assuming the API returns the count in response.data.driverCount
      })
      .catch((error) => {
        console.error("Error fetching driver count:", error);
      });
  }, [rerendarApi]);
  useEffect(() => {}, [driverCount]);

  //VEHICLE
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/v1/vehicles`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setVehicleCount(response.data.vehicleCount);

        // Calculate total kilometers
        const totalKm = response.data.vehicles.reduce(
          (sum, vehicle) => sum + (vehicle.totalkm || 0),
          0
        );
        setTotalKm(totalKm);
      })
      .catch((error) => {
        console.error("Error fetching vehicle count:", error);
      });
  }, [rerendarApi]);
  useEffect(() => {}, [vehicleCount]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/v1/dashboard`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setUserCount(response.data.totalActiveUsers || 0);
      })
      .catch((error) => {
        console.error("Error fetching Active Vehicles:", error);
      });
  }, [rerendarApi]);

  //HUBS
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/v1/hubs `, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setHubCount(response.data.hubCount);
      })
      .catch((error) => {
        console.error("Error fetching hub count:", error);
      });
  }, [rerendarApi]);
  useEffect(() => {}, [hubCount]);

  //CLIENTS
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/v1/clients`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setClientCount(response.data.count);
      })
      .catch((error) => {
        console.error("Error fetching client count:", error);
      });
  }, [rerendarApi]);

  // Log vehicleCount to see if it updates
  useEffect(() => {}, [clientCount]);

  // Fetch daily kilometers
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/v1/dashboard`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setDailykm(response.data.totalDailykmDriven || 0);
      })
      .catch((error) => {
        console.error("Error fetching daily kilometers:", error);
      });
  }, [rerendarApi]);

  //General Staff
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/v1/general-staffs `, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setGeneralStaffCount(response.data.generalStaffCount);
      })
      .catch((error) => {
        console.error("Error fetching general staff count:", error);
      });
  }, [rerendarApi]);
  useEffect(() => {}, [generalStaffCount]);

  // Log vehicleCount to see if it updates
  useEffect(() => {}, [generalStaffCount]);

  useEffect(() => {
    setData({ cron1: "cron 1", cron2: "cron 2" });
  }, [rerendarApi]);
  // copy cron
  const copyLink = () => {
    navigator.clipboard.writeText(data.cron1);
    setRerendarApi(!rerendarApi);
    Swal.fire("Copy Cron Url", "", "success");
  };
  const copyLink2 = () => {
    navigator.clipboard.writeText(data.cron2);
    setRerendarApi(!rerendarApi);
    Swal.fire("copy success", "", "success");
  };
  return (
    <>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div
            className="col d-flex align-items-center"
            style={{ whiteSpace: "nowrap" }}
          >
            <span className="text-xl" style={{ fontSize: "150%" }}>
              Dashboard{" "}
            </span>
          </div>
        </div>
        <div className="row ">
          <div className="col-lg-3 col-sm-6 col-12 d-flex">
            <div className="dash-count">
              <div className="dash-counts">
                <h4>
                  <CountUp end={vehicleCount} />
                </h4>
                <h5>Vehicles</h5>
              </div>
              <div className="dash-imgs">
                <FontAwesomeIcon
                  icon={faCreditCard}
                  size="2xl"
                  style={{ color: "rgba(0,0,0,.15)" }}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 col-12 d-flex">
            <div className="dash-count das1">
              <div className="dash-counts">
                <h4>
                  <CountUp end={driverCount} />
                </h4>

                <h5>Drivers</h5>
              </div>
              <div className="dash-imgs">
                <FontAwesomeIcon
                  icon={faEyeLowVision}
                  size="lg"
                  style={{ color: "rgba(0,0,0,.15)" }}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 col-12 d-flex">
            <div className="dash-count das2">
              <div className="dash-counts">
                <h4>
                  <CountUp end={hubCount} />
                </h4>

                <h5>Hubs</h5>
              </div>
              <div className="dash-imgs">
                <FontAwesomeIcon
                  icon={faBuildingColumns}
                  size="lg"
                  style={{ color: "rgba(0,0,0,.15)" }}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 col-12 d-flex">
            <div className="dash-count das3">
              <div className="dash-counts">
                <h4>
                  <CountUp end={clientCount} />
                </h4>

                <h5>Clients</h5>
              </div>
              <div className="dash-imgs">
                <FontAwesomeIcon
                  icon={faEyeLowVision}
                  size="lg"
                  style={{ color: "rgba(0,0,0,.15)" }}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 col-12 d-flex">
            <div className="dash-count das3">
              <div className="dash-counts">
                <h4>
                  <CountUp end={generalStaffCount} />
                </h4>

                <h5>General Staff</h5>
              </div>
              <div className="dash-imgs">
                <FontAwesomeIcon
                  icon={faEyeLowVision}
                  size="lg"
                  style={{ color: "rgba(0,0,0,.15)" }}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 col-12 d-flex">
            <div className="dash-count das3">
              <div className="dash-counts">
                <h4>
                  <CountUp end={totalKm} />
                </h4>

                <h5>Total Kilometers</h5>
              </div>
              <div className="dash-imgs">
                <FontAwesomeIcon
                  icon={faFileInvoice}
                  size="lg"
                  style={{ color: "rgba(0,0,0,.15)" }}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 col-12 d-flex">
            <div className="dash-count das3">
              <div className="dash-counts">
                <h4>
                  <CountUp end={dailyKm} />
                </h4>

                <h5>Daily Kilometers</h5>
              </div>
              <div className="dash-imgs">
                <FontAwesomeIcon
                  icon={faFileInvoice}
                  size="lg"
                  style={{ color: "rgba(0,0,0,.15)" }}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 col-12 d-flex">
            <div className="dash-count das3">
              <div className="dash-counts">
                <h4>
                  <CountUp end={userCount} />
                </h4>

                <h5>Total Users</h5>
              </div>
              <div className="dash-imgs">
                <FontAwesomeIcon
                  icon={faFileInvoice}
                  size="lg"
                  style={{ color: "rgba(0,0,0,.15)" }}
                />
              </div>
            </div>
          </div>

          <div className="row">
            {/* Container for both Pie charts */}
            <div className="col-12 mb-4 d-flex justify-content-between">
              {/* Pie chart for Vehicle Companies */}
              <div className="card me-2" style={{ flex: "1 1 45%" }}>
                <div className="card-body">
                  <h5>
                    <b>Vehicle Distribution by Company</b>
                  </h5>
                  <br />
                  {/* Container for Pie Chart and Legend */}
                  <div className="chart-container">
                    <div className="pie-chart" style={{ marginRight: "30px" }}>
                      <Pie data={chartData} options={options} />
                    </div>
                    <div className="legend-container">
                      {chartData.labels &&
                        chartData.labels.map((label, index) => {
                          const count = chartData.datasets[0].data[index]; // Get count for the label
                          const total = chartData.datasets[0].data.reduce(
                            (sum, value) => sum + value,
                            0
                          ); // Total vehicles
                          const percentage = total
                            ? ((count / total) * 100).toFixed(2)
                            : 0; // Calculate percentage

                          return (
                            <div key={index} className="legend-item">
                              <span
                                className="legend-color"
                                style={{
                                  backgroundColor:
                                    chartData.datasets[0].backgroundColor[
                                      index
                                    ],
                                }}
                              ></span>
                              {`${label} : ${count} (${percentage}%)`}
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>

              {/* Pie Chart for Status */}
              <div className="card" style={{ flex: "1 1 45%" }}>
                <div className="card-body">
                  <h5>
                    <b>Vehicle Distribution by Status</b>
                  </h5>
                  <br />
                  {/* Container for Pie Chart and Legend */}
                  <div className="chart-container">
                    <div className="pie-chart" style={{ marginRight: "30px" }}>
                      <Pie data={statusChartData} options={options} />
                    </div>
                    <div className="legend-container">
                      {statusChartData.labels &&
                        statusChartData.labels.map((label, index) => {
                          const count = statusChartData.datasets[0].data[index]; // Get count for the label
                          const total = statusChartData.datasets[0].data.reduce(
                            (sum, value) => sum + value,
                            0
                          ); // Total vehicles
                          const percentage = total
                            ? ((count / total) * 100).toFixed(2)
                            : 0; // Calculate percentage

                          return (
                            <div key={index} className="legend-item">
                              <span
                                className="legend-color"
                                style={{
                                  backgroundColor:
                                    statusChartData.datasets[0].backgroundColor[
                                      index
                                    ],
                                }}
                              ></span>
                              {`${label} : ${count} (${percentage}%)`}
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 mb-4">
            <div className="card">
              <div className="card-body">
                <h5>
                  <b>Vehicle Status by Company</b>
                </h5>
                <br />
                <div className="chart-container">
                  <Bar
                    data={barChartData}
                    options={{
                      responsive: true,
                      scales: {
                        x: {
                          stacked: true,
                        },
                        y: {
                          stacked: true,
                          beginAtZero: true,
                        },
                      },
                      plugins: {
                        legend: {
                          display: true, // Display the legend
                        },
                        tooltip: {
                          callbacks: {
                            label: (context) => {
                              const datasetLabel = context.dataset.label || "";
                              const value = context.raw || 0;
                              return `${datasetLabel}: ${value}`;
                            },
                          },
                        },
                        datalabels: {
                          display: true, // Display the labels
                          color: "white", // Color of the label text
                          align: "center",
                          anchor: "center",
                          font: {
                            weight: "bold", // Bold text
                          },
                          formatter: (value, context) => {
                            const datasetLabel = context.dataset.label || "";
                            return value > 0
                              ? `${datasetLabel} - ${value}`
                              : ""; // Format as "Active - 10"
                          },
                        },
                      },
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="card-body">
            <MyMapComponent />
          </div>
        </div>
      </div>
    </>
  );
}
