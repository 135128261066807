import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Breadcrumb from "../../components/breadcrumb/index";
import Modal from "react-modal";
import Layouts from "../../layouts/layouts";
import Select from "react-select";

Modal.setAppElement("#root");

export default function Create_damage() {
  const title = "Add Damages";
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [categories, setCategories] = useState([]);

  const [formData, setFormData] = useState({
    id_user: "",
    // dailyDeliver: "",
    // date: "",
    id_vehicle: "",
    id_category: "",
    description: "",
  });

  const [errors, setErrors] = useState({});

  const brad = [
    {
      name: "Home",
    },
    {
      name: title,
    },
  ];

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found");
          return;
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/admin/users`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setUsers(response.data.users);
      } catch (error) {
        console.error("Error fetching users:", error);
        setUsers([]);
      }
    };
    fetchUsers();
  }, []);

  useEffect(() => {
    const fetchVehicles = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found");
          return;
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/vehicles`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(response.data.vehicles);
        setVehicles(response.data.vehicles);
        console.log("Vehicles fetched:", response.data.vehicles);
      } catch (error) {
        console.error("Error fetching vehicles:", error);
        setVehicles([]);
      }
    };
    fetchVehicles();
  }, []);
  const handleVehicleChange = (selectedOption) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      id_vehicle: selectedOption ? selectedOption.value : "",
    }));
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found");
          return;
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/damage-category`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(response.data.categories);
        setCategories(response.data.categories);
        console.log("Categories fetched:", response.data.categories);
      } catch (error) {
        console.error("Error fetching categories:", error);
        setCategories([]);
      }
    };
    fetchCategories();
  }, []);
  const handleCategoryChange = (selectedOption) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      id_category: selectedOption ? selectedOption.value : "",
    }));
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.id_vehicle) {
      newErrors.id_vehicle = "Vehicle Id  is required.";
    }
    if (!formData.id_category) {
      newErrors.id_category = "Category   is required.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const onChangeHandler = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleUserChange = (selectedOption) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      id_user: selectedOption ? selectedOption.value : "",
    }));
  };

  const onSubmitHandler = async (event) => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }

    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found");
      return;
    }

    const data = new FormData();
    if (formData.id_user) {
      data.append("id_user", formData.id_user);
    }
    if (formData.description) {
      data.append("description", formData.description);
    }
    data.append("id_vehicle", formData.id_vehicle);
    data.append("id_category", formData.id_category);

    for (let [key, value] of data.entries()) {
      console.log(`${key}: ${value}`);
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v1/damage`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Response data:", response.data);

      if (response.status === 201) {
        navigate("/damage");
      } else {
        console.error("Submission failed:", response.statusText);
      }
    } catch (error) {
      //   console.error("Error:", error);

      console.error("Error:", error.response ? error.response.data : error);
    }
  };

  return (
    <>
      <Layouts />
      <div className="container-fluid">
        <Breadcrumb title={title} brad={brad} />
        <form
          className="card"
          style={{ borderTop: "2px solid #4723d9" }}
          onSubmit={onSubmitHandler}
        >
          <div className="card-header d-flex justify-content-between border-bottom pb-1">
            <div className="">{title}</div>
            <div className="btn btn-info btn-sm" onClick={() => navigate(-1)}>
              Back
            </div>
          </div>
          <div className="card-body">
            <div className="row justify-content-center">
              <div className="col-md-6 mt-3">
                <label htmlFor="id_user">
                  <b>User Name </b>
                </label>
                <Select
                  name="id_user"
                  options={users.map((user) => ({
                    value: user._id,
                    label: `${user.name || "Unknown Name"} (${
                      user.phonenumber || "Unknown Phone"
                    })`,
                  }))}
                  onChange={handleUserChange}
                  classNamePrefix="select"
                />
              </div>

              <div className="col-md-6 mt-3">
                <label htmlFor="id_category">
                  <b>Category *</b>
                </label>
                <Select
                  name="id_category"
                  options={categories.map((category) => ({
                    value: category._id,
                    label: `${category.name}`,
                  }))}
                  onChange={(selectedOption) =>
                    handleCategoryChange(selectedOption)
                  }
                  classNamePrefix="select"
                />
                {errors.id_category && (
                  <p className="text-danger">{errors.id_category}</p>
                )}
              </div>

              <div className="col-md-6 mt-3">
                <label htmlFor="id_vehicle">
                  <b>Vehicle *</b>
                </label>
                <Select
                  name="id_vehicle"
                  options={vehicles.map((vehicle) => ({
                    value: vehicle._id,
                    label: `${vehicle.numberplate}`,
                  }))}
                  onChange={(selectedOption) =>
                    handleVehicleChange(selectedOption)
                  }
                  classNamePrefix="select"
                />
                {errors.id_vehicle && (
                  <p className="text-danger">{errors.id_vehicle}</p>
                )}
              </div>

              <div className="col-md-6 mt-3"></div>
              <div className="col-12 mt-3">
                <label htmlFor="description">
                  <b>Descriptions *</b>
                </label>
                <textarea
                  name="description"
                  type="text"
                  placeholder="Enter Description"
                  rows="5"
                  className="form-control"
                  value={formData.description}
                  onChange={onChangeHandler}
                ></textarea>
              </div>
            </div>
            <button type="submit" className="btn btn-primary btn-sm mt-3">
              Add Damage
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
